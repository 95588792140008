import { ID_TO_GRAIN } from '../constants/dimensions';
export const getCm360Linking = (datasetRules, platform_grain_id) => {
    const linking = datasetRules?.find(
        rule => rule?.type === 'cm360Mapping' && rule?.config?.target_id === platform_grain_id,
    );
    if (linking) {
        const resolvedGrain = ID_TO_GRAIN[linking?.config?.source_level];
        linking.source_platform_grain = resolvedGrain;
        linking.source_information = {
            platform: linking.config?.source_platform,
            name: linking.config?.source_name,
            grain: linking.config?.source_level,
        };
    }

    return linking;
};
export const getAliases = (datasetRules, platform_grain_id, auto_alias) => {
    const alias = datasetRules?.find(
        rule =>
            rule?.config?.key === platform_grain_id &&
            rule?.type === 'alias' &&
            rule.config.field !== 'creative_size_id',
    );
    if (alias) {
        return alias;
    }
    if (auto_alias) {
        return { config: { alias: auto_alias } };
    }
    return undefined;
};
export const getSizeAliases = (datasetRules, platform_grain_id) => {
    const sizeAlias = datasetRules?.find(
        rule =>
            rule?.config?.key === platform_grain_id &&
            rule?.type === 'alias' &&
            rule.config.field === 'creative_size_id',
    );
    return sizeAlias;
};
export const getFilters = (datasetRules, platform_grain_id) => {
    const omit = datasetRules.some(
        rule => rule.type === 'grainFilter' && rule.config.key === platform_grain_id,
    );
    return omit;
};

export const getTags = (datasetRules, platform_grain_id) => {
    const tags =
        datasetRules
            ?.filter(
                rule =>
                    rule.type === 'tag' &&
                    rule.config.tag_type === 'alias' &&
                    rule.config.grain_id === platform_grain_id,
            )
            .reduce((t, tag) => {
                t[tag.config.bucket] = tag;
                return t;
            }, {}) || {};

    const assetsAsTags = datasetRules?.find(
        rule => ['image'].includes(rule.type) && rule.config.grain_id === platform_grain_id,
    );

    if (assetsAsTags && !tags.creative) {
        tags.creative = assetsAsTags;
    }

    return tags;
};

export const getActivityBuckets = (datasetRules, activity) => {
    const matchActivity =
        datasetRules?.filter(
            rule =>
                rule?.config?.key === activity.activityID &&
                rule?.type === 'tag' &&
                rule?.config?.tag_value === 'activities',
        ) || [];
    const matchAdditionalFilters = matchActivity.filter(rule => {
        if (!rule?.config?.additional_filters || rule?.config?.additional_filters.length === 0) {
            return true;
        }
        return rule?.config?.additional_filters.every(filter => {
            return filter.field === `${activity.grainType}_id` && filter.key === activity.grainID;
        });
    });

    return matchAdditionalFilters;
};

export const getActivityAliases = (datasetRules, activity) => {
    let matchActivity = datasetRules?.filter(
        rule =>
            rule?.type === 'alias' &&
            rule?.config?.key === activity.activityID &&
            rule?.config?.default_field === 'activity_name_alias',
    );
    if (matchActivity.length > 0 && matchActivity[0]?.config?.additional_filters?.length > 0) {
        const matchesAllAdditionalFilters = matchActivity.find(rule =>
            rule?.config?.additional_filters?.every(
                filter =>
                    filter.field === `${activity.grainType}_id` && filter.key === activity.grainID,
            ),
        );
        matchActivity = matchesAllAdditionalFilters;
        if (!matchesAllAdditionalFilters) {
            matchActivity = null;
        }
    } else {
        matchActivity = matchActivity[0];
    }
    return matchActivity;
};

export const applyDimensionRules = (datasetRules, platform_grain_id, auto_alias) => {
    const result = {
        cm360_linking: getCm360Linking(datasetRules, platform_grain_id),
        alias: getAliases(datasetRules, platform_grain_id, auto_alias),
        sizeAlias: getSizeAliases(datasetRules, platform_grain_id),
        omit: getFilters(datasetRules, platform_grain_id),
        tags: getTags(datasetRules, platform_grain_id),
    };
    return result;
};

export const applyActivityRules = (datasetRules, activity) => {
    const activityBuckets = getActivityBuckets(datasetRules, activity);
    const activityAlias = getActivityAliases(datasetRules, activity);
    const result = {};
    activityBuckets.forEach(bucket => (result[bucket.config.bucket] = bucket));

    if (activityAlias) {
        result.alias = activityAlias;
    }

    return result;
};

export const getInheritedRules = (path, datasetRules) =>
    path?.reduce(
        (ruleSet, ancestor) => {
            const tags = getTags(datasetRules, ancestor);
            const alias = getAliases(datasetRules, ancestor);

            // path stores data most recent parent first, so first get parents, and only add grandparents
            // if there isn't a parent tag for that object.
            ruleSet.tags = { ...tags, ...ruleSet.tags };

            if (alias && !ruleSet.alias) {
                ruleSet.alias = alias;
            }

            return ruleSet;
        },
        { tags: {} },
    );

const isCreativeUrlRule = rule =>
    rule.type === 'image' || (rule?.type === 'tag' && rule?.config?.bucket === 'creative');

const areBothCreatives = (ruleA, ruleB) => isCreativeUrlRule(ruleA) && isCreativeUrlRule(ruleB);

export const deleteCM360Rule = (updatedRules, source_id, target_id) => {
    const ruleIndex = updatedRules.findIndex(rule => {
        return rule?.config?.source_id === source_id && rule?.config?.target_id === target_id;
    });
    if (ruleIndex !== -1) {
        updatedRules.splice(ruleIndex, 1);
    }
};
export const checkSameRow = (rule, newRule) => {
    const sameBase = rule.config.key === newRule.config.key;
    let additionalFiltersMatch;
    // legacy datasets were created without additional_filters - only compare values if the existing rule contains an array (assume a match if undefined)
    if (
        rule.config.additional_filters &&
        (rule.config.additional_filters?.length > 0 ||
            newRule.config.additional_filters?.length > 0)
    ) {
        additionalFiltersMatch = rule.config.additional_filters?.every(filter =>
            newRule.config.additional_filters?.some(
                f => f.field === filter.field && f.key === filter.key,
            ),
        );
    } else {
        additionalFiltersMatch = true;
    }
    return sameBase && additionalFiltersMatch;
};
export const updateRules = (updatedRules, newRule, deleteRule) => {
    const ruleIndex = updatedRules.findIndex(rule => {
        const bothCreatives = areBothCreatives(rule, newRule);
        const isSameRow = checkSameRow(rule, newRule);
        const isSameType = rule.type === newRule.type;

        let isSameRule = true;
        if (newRule.type === 'cm360Mapping') {
            isSameRule = false;
        }
        if (newRule.type === 'tag') {
            isSameRule = newRule.config.bucket === rule.config.bucket;
        } else if (newRule.type === 'alias') {
            isSameRule = newRule.config.field === rule.config.field;
        }

        return isSameRow && ((isSameType && isSameRule) || bothCreatives);
    });
    if (ruleIndex === -1) {
        if (!deleteRule) {
            updatedRules.push(newRule);
        }
    } else if (deleteRule) {
        updatedRules.splice(ruleIndex, 1);
    } else {
        updatedRules[ruleIndex] = newRule;
    }
};

export const mapPricingGrain = (grains, datasetRules) =>
    grains?.map(grain => {
        const alias = getAliases(datasetRules, grain.platform_grain_id);

        if (alias) {
            return {
                ...grain,
                alias,
            };
        }

        return grain;
    }) || [];
