import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import StyledDatePicker from './StyledDatePicker';

const DateInputTagCell = ({
    row,
    tagId,
    applyRowChange,
    rowIndex,
    initialValueGetter = tags => tags && tags[tagId] && tags[tagId].config?.tag_value,
    grainType,
}) => {
    const setTagToDate = tag => {
        if (tag) {
            return dayjs(tag);
        }
        return null;
    };
    const [tag, setTag] = useState(setTagToDate(initialValueGetter(row?.tags)));
    const [inheritedValue, setInheritedValue] = useState(
        setTagToDate(initialValueGetter(row.inheritedRules?.tags)),
    );
    const initialValueRef = useRef(setTagToDate(initialValueGetter(row?.tags)));

    useEffect(() => {
        const initialTagValue = initialValueGetter(row.tags);
        setTag(initialTagValue);
        initialValueRef.current = initialTagValue;
    }, [row?.tags]);

    useEffect(() => {
        setInheritedValue(setTagToDate(initialValueGetter(row.inheritedRules?.tags)));
    }, [row?.inheritedRules]);
    const grain = `${grainType}_id`;
    return (
        <StyledDatePicker
            value={tag}
            slotProps={{
                textField: {
                    placeholder: inheritedValue
                        ? inheritedValue.format('MM/DD/YYYY')
                        : 'MM/DD/YYYY',
                },
            }}
            onChange={date => {
                if (date !== initialValueRef.current) {
                    applyRowChange(
                        row,
                        {
                            type: 'tag',
                            grain: {
                                platform_grain_id: row.grainID,
                                name: row.alias?.config?.value || row.name,
                                platform: row.platform,
                                type: grainType,
                            },
                            config: {
                                tag_type: 'alias',
                                field: grain,
                                key: row.grainID,
                                bucket: tagId,
                                grain,
                                grain_id: row.grainID,
                                tag_value: date,
                                reference: false,
                            },
                        },
                        rowIndex,
                        !date,
                    );
                }
            }}
            sx={{ display: 'flex', width: '100%' }}
            emptyLabel='custom label'
        />
    );
};

export default DateInputTagCell;
