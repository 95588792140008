import { TextField } from '@mui/material';
import { GOODWAY_SAND_400, GOODWAY_YELLOW } from '../../constants/colors';
import { stripSpecialCharacters } from '../../helpers/utils';
const CellTextField = ({
    value,
    placeholder,
    label,
    onBlur = () => {},
    onChange = () => {},
    allowSpecialCharacters = false,
    inputProps,
    inputLabelProps,
    style,
}) => {
    const stripSpecial = e => {
        if (!allowSpecialCharacters) {
            e.target.value = stripSpecialCharacters(e.target.value);
        }

        onChange(e);
    };

    return (
        <TextField
            value={value || ''}
            placeholder={placeholder}
            label={label}
            onBlur={onBlur}
            onChange={stripSpecial}
            variant={'outlined'}
            InputLabelProps={inputLabelProps}
            sx={{
                width: '100%',
                position: 'relative',
                marginTop: 1,
                marginBottom: 1,
                input: {
                    height: 12,
                    color: 'white',
                    marginBottom: '-4px',
                },
                fieldset: {
                    borderColor: GOODWAY_SAND_400,
                    borderRadius: '10px',
                },
                '& .MuiOutlinedInput-root:hover': {
                    fieldset: {
                        borderColor: GOODWAY_YELLOW,
                    },
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused': {
                        fieldset: {
                            borderColor: GOODWAY_YELLOW,
                        },
                    },
                },
                ...style,
            }}
            onKeyDown={event => event.stopPropagation()}
            InputProps={inputProps}
        />
    );
};

export default CellTextField;
