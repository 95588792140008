import { GOODWAY_YELLOW } from '../../constants/colors';
import withBulkEdit from '../../helpers/withBulkEdit';
import StyledDatePicker from '../atoms/StyledDatePicker';

const BulkEditDatePicker = ({ label, column, value, setValue }) => (
    <StyledDatePicker
        value={value}
        onChange={date => setValue(date)}
        label={<span style={{ color: 'white' }}>{label || column.headerName}</span>}
        slotProps={{
            textField: {
                placeholder: 'MM/DD/YYYY',
                style: { label: { color: GOODWAY_YELLOW } },
            },
        }}
    />
);

export default withBulkEdit(BulkEditDatePicker);
