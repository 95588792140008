import { updateTagValue } from '../../helpers/utils';
import BulkEditDatePicker from './BulkEditDatePicker';

const BulkEditTagFieldDate = ({ column, ...props }) => {
    const updateRow = (row, value) =>
        updateTagValue(value, row, column.tagId, column.currentDimension);

    return <BulkEditDatePicker {...props} updateRow={updateRow} column={column} />;
};

export default BulkEditTagFieldDate;
